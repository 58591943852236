import titleCase from "./titleCase"

const cleanCrumbName = name => {
  if (!name) {
    return null
  }
  return titleCase(name.replace(/-/g, " "))
}

export const createCrumbDataFromLocationPath = url => {
  let crumbs = url.replace("/", "").split("/")

  const breadcrumbData = crumbs.reduce((breadcrumbs, crumb, i) => {
    let path = crumbs.slice(0, i+1)
    path = `/${path.join("/")}`

    return [...breadcrumbs, {name: cleanCrumbName(crumb), path}]
  }, [{ name: "Home", path: "/" }])

  return breadcrumbData
}

export const createCrumbDataFromUrl = url => {
  let crumbs = url.replace(/([^\/]*\/){3}/, "").split("/")
  crumbs.pop()

  const breadcrumbData = crumbs.reduce((breadcrumbs, crumb, i) => {
    let path = crumbs.slice(0, i+1)
    path = `/${path.join("/")}`

    return [...breadcrumbs, {name: cleanCrumbName(crumb), path}]
  }, [{ name: "Home", path: "/" }])

  return breadcrumbData
}
