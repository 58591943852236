import React from "react"
import { graphql } from "gatsby"
import loadable from "@loadable/component"
import { v4 as uuidv4 } from "uuid"

import Layout from "../components/Layout"
import { makePageSchema } from "../utils/seoHelper"

import { createCrumbDataFromLocationPath } from "../utils/crumbHelper"

const acfComponents = {
  HeaderParagraph: loadable(() =>
    import("../components/AcfPageBlocks/HeaderParagraph")
  ),
  PromoBoxes: loadable(() => import("../components/AcfPageBlocks/PromoBoxes")),
  FeaturedCategoriesSection: loadable(() =>
    import("../components/AcfPageBlocks/FeaturedCategories")
  ),
  Branches: loadable(() => import("../components/AcfPageBlocks/Branches")),
  NewsletterSubscribe: loadable(() =>
    import("../components/AcfPageBlocks/NewsletterSubscribe")
  ),
  BrandsCarousel: loadable(() =>
    import("../components/AcfPageBlocks/BrandsCarousel")
  ),
  BannerSlider: loadable(() =>
    import("../components/AcfPageBlocks/BannerSlider")
  ),
  OptionsSection: loadable(() =>
    import("../components/AcfPageBlocks/OptionsSection")
  ),
  StaffPicksCarousel: loadable(() =>
    import("../components/AcfPageBlocks/StaffPicksCarousel")
  ),
  // RecommendedFourProducts: loadable(() =>
  //   import("../components/AcfPageBlocks/RecommendedFourProducts")
  // ),
  BlogPostList: loadable(() =>
    import("../components/AcfPageBlocks/BlogPostList")
  ),
  BannerImage: loadable(() =>
    import("../components/AcfPageBlocks/BannerImage")
  ),
  HeaderAndList: loadable(() =>
    import("../components/AcfPageBlocks/HeaderAndList")
  ),
  TwoImgWithHeaderAndList: loadable(() =>
    import("../components/AcfPageBlocks/TwoImgWithHeaderAndList")
  ),
  RightImage: loadable(() => import("../components/AcfPageBlocks/RightImage")),
  TextWithTwoBoxes: loadable(() =>
    import("../components/AcfPageBlocks/TextWithTwoBoxes")
  ),
  TextBlock: loadable(() => import("../components/AcfPageBlocks/TextBlock")),
  TestRideFormTriggerButton: loadable(() =>
    import("../components/AcfPageBlocks/TestRideFormTriggerButton")
  ),
  HomeBanner: loadable(() => import("../components/AcfPageBlocks/HomeBanner")),
  TextBoxAndLargeRightImg: loadable(() =>
    import("../components/AcfPageBlocks/TextBoxAndLargeRightImg")
  ),
  FeatureBanner: loadable(() =>
    import("../components/AcfPageBlocks/FeatureBanner")
  ),
  FeaturePoints: loadable(() =>
    import("../components/AcfPageBlocks/FeaturePoints")
  ),
  TextBoxOnBkgdImg: loadable(() =>
    import("../components/AcfPageBlocks/TextBoxOnBkgdImg")
  ),
  TwoCtaImgBoxes: loadable(() =>
    import("../components/AcfPageBlocks/TwoCtaImgBoxes")
  ),
  TwoImagesWithLogoOption: loadable(() =>
    import("../components/AcfPageBlocks/TwoImagesWithLogoOption")
  ),
  ReviewSlider: loadable(() =>
    import("../components/AcfPageBlocks/ReviewSlider")
  ),
  TextAndLeftImage: loadable(() =>
    import("../components/AcfPageBlocks/TextAndLeftImage")
  ),
  TwoImagesStripWithBtns: loadable(() =>
    import("../components/AcfPageBlocks/TwoImagesStripWithBtns")
  ),
  ThreePostsRow: loadable(() =>
    import("../components/AcfPageBlocks/ThreePostsRow")
  ),
  HomeBannerVariant2: loadable(() =>
    import("../components/AcfPageBlocks/HomeBannerVariant2")
  ),
  ProcessFlow: loadable(() =>
    import("../components/AcfPageBlocks/ProcessFlow")
  ),
  ThreeLinkedImageBoxes: loadable(() =>
    import("../components/AcfPageBlocks/ThreeLinkedImageBoxes/")
  ),
  TextImageAndStats: loadable(() =>
    import("../components/AcfPageBlocks/TextImageAndStats")
  ),
  TextPanelWithTwoBkgdImgs: loadable(() =>
    import("../components/AcfPageBlocks/TextPanelWithTwoBkgdImgs")
  ),
  ProductBrandFilter: loadable(() =>
    import("../components/AcfPageBlocks/ProductBrandFilter")
  ),
  BrowesTabs: loadable(() => import("../components/AcfPageBlocks/BrowesTabs")),
  BranchesVariant2: loadable(() =>
    import("../components/AcfPageBlocks/BranchesVariant2")
  ),
  ProductsCarousel: loadable(() =>
    import("../components/AcfPageBlocks/ProductsCarousel")
  ),
  HeaderWithPopupOption: loadable(() =>
    import("../components/AcfPageBlocks/HeaderWithPopupOption")
  ),
  LeftImage: loadable(() => import("../components/AcfPageBlocks/LeftImage")),
  CategoriesCarousel: loadable(() =>
    import("../components/AcfPageBlocks/CategoriesCarousel")
  ),
  CarouselWithMarketingContent: loadable(() =>
    import("../components/AcfPageBlocks/CarouselWithMarketingContent")
  ),
  CoveringImageAndText: loadable(() =>
    import("../components/AcfPageBlocks/CoveringImageAndText")
  ),
  ExternalProductsCarousel: loadable(() =>
    import("../components/AcfPageBlocks/ExternalProductsCarousel")
  ),
  Faq: loadable(() => import("../components/AcfPageBlocks/Faq/")),
  FaqVariant2: loadable(() =>
    import("../components/AcfPageBlocks/FaqVariant2/")
  ),
  BrandsCarouselVariant2: loadable(() =>
    import("../components/AcfPageBlocks/BrandsCarouselVariant2")
  ),
  HeaderWithTaglineOption: loadable(() =>
    import("../components/AcfPageBlocks/HeaderWithTaglineOption")
  ),
  RecommendationsQuiz: loadable(() =>
    import("../components/AcfPageBlocks/RecommendationsQuiz")
  ),
  ImageBoxesBlock: loadable(() =>
    import("../components/AcfPageBlocks/ImageBoxesBlock")
  ),
  VideoCallToAction: loadable(() =>
    import("../components/AcfPageBlocks/VideoCallToAction")
  ),
  StoreLocator: loadable(() =>
    import("../components/AcfPageBlocks/StoreLocator")
  ),
  ContactForm: loadable(() =>
    import("../components/AcfPageBlocks/ContactForm")
  ),
  ContactFormPlain: loadable(() =>
    import("../components/AcfPageBlocks/ContactFormPlain")
  ),
  HeaderWithOptionalButtons: loadable(() =>
    import("../components/AcfPageBlocks/HeaderWithOptionalButtons")
  ),
  ClientPanel: loadable(() => import("../components/ClientPanel")),
  CompanyStats: loadable(() =>
    import("../components/AcfPageBlocks/CompanyStats")
  ),
  SimpleContent: loadable(() =>
    import("../components/AcfPageBlocks/SimpleContent")
  ),
  Services: loadable(() => import("../components/AcfPageBlocks/Services")),
  BackgroundVideoHeader: loadable(() =>
    import("../components/AcfPageBlocks/BackgroundVideoHeader")
  ),
}

const Page = ({ location, data }) => {
  const {
    wpPage: {
      title,
      content,
      uri,
      flexLayouts: { flexibleLayouts },
      seo: {
        fullHead,
        schema: { raw: schema },
      },
    },
    allWpPost,
    googlePlacesPlace,
    allGooglePlacesReview,
  } = data

  if (typeof window !== "undefined") {
    const root = document.querySelector(":root")
    root.style.setProperty("--google-rating", googlePlacesPlace.rating)
  }

  let searchConsoleVerification
  if (uri === "/home") {
    searchConsoleVerification = "SVriGJpU573d-Vn8gJ_YYDfwk4eaWDFwPYmKTfWKgnE"
  }

  const pageSchema = makePageSchema(schema)
  const breadcrumbData = createCrumbDataFromLocationPath(location.pathname)
  return (
    <Layout
      seoHeadTags={fullHead}
      schema={pageSchema}
      location={location}
      searchConsole={searchConsoleVerification}>
      {content && (
        <div className={title} dangerouslySetInnerHTML={{ __html: content }} />
      )}
      {flexibleLayouts &&
        flexibleLayouts.length > 0 &&
        flexibleLayouts.map((block, i) => {
          if (!block) {
            console.warn(
              `Crikey! There was a problem rendering a block. It's value is ${block}.`
            )
            return null
          }

          const componentName = block.__typename.replace(
            /WpPage_Flexlayouts_FlexibleLayouts_/,
            ""
          )
          const Component = acfComponents[componentName]

          if (!Component) {
            console.warn(
              `Oh my! There was a problem rendering ${componentName}.`
            )
            return null
          }

          return (
            <Component
              key={uuidv4()}
              {...block}
              breadcrumbData={breadcrumbData}
              defaultPosts={allWpPost.nodes}
              location={location}
              googleReviewData={{ googlePlacesPlace, allGooglePlacesReview }}
            />
          )
        })}
    </Layout>
  )
}

export default Page

export const pageQuery = graphql`
  query GET_PAGE($pageId: String!, $placeId: String!) {
    wpPage(id: { eq: $pageId }) {
      title
      content
      uri
      date
      flexLayouts {
        flexibleLayouts {
          __typename
          ...HeaderParagraphFragment
          ...PromoBoxesFragment
          ...FeaturedCategoriesSectionFragment
          ...BranchesFragment
          ...NewsletterSubscribeFragment
          ...BrandsCarouselFragment
          ...BannerSliderFragment
          ...OptionsSectionFragment
          ...StaffPicksCarouselFragment
          ...BlogPostListFragment
          # ...RecommendedFourProductsFragment
          ...BannerImageFragment
          ...HeaderAndListFragment
          ...TwoImgWithHeaderAndListFragment
          ...RightImageFragment
          ...TextWithTwoBoxesFragment
          ...TextBlockFragment
          ...TestRideFormTriggerButtonFragment
          ...HomeBannerFragment
          ...TextBoxAndLargeRightImgFragment
          ...FeatureBannerFragment
          ...FeaturePointsFragment
          ...TextBoxOnBkgdImgFragment
          ...ProcessFlowFragment
          ...TwoCtaImgBoxesFragment
          ...ThreeLinkedImageBoxesFragment
          ...TwoCtaImgBoxesFragment
          ...TwoImagesWithLogoOptionFragment
          ...ThreeLinkedImageBoxesFragment
          ...ReviewSliderFragment
          ...TextAndLeftImageFragment
          ...TwoImagesStripWithBtnsFragment
          ...ThreePostsRowFragment
          ...HomeBannerVariant2Fragment
          ...ProcessFlowFragment
          ...TextImageAndStatsFragment
          ...TextPanelWithTwoBkgdImgsFragment
          ...ProductBrandFilterFragment
          ...BrowesTabsFragment
          ...BranchesVariant2Fragment
          ...ProductsCarouselFragment
          ...HeaderWithPopupOptionFragment
          ...LeftImageFragment
          ...CategoriesCarouselFragment
          ...CarouselWithMarketingContentFragment
          ...CoveringImageAndTextFragment
          ...ExternalProductsCarouselFragment
          ...FaqFragment
          ...RecommendationsQuizFragment
          ...FaqVariant2Fragment
          ...BrandsCarouselVariant2Fragment
          ...HeaderWithTaglineOptionFragment
          ...VideoCallToActionFragment
          ...ContactFormFragment
          ...ContactFormPlainFragment
          ...HeaderWithOptionalButtonsFragment
          ...ImageBoxesBlockFragment
          ...backgroundVideoHeaderFragment
          ...StoreLocatorFragment
          ...CompanyStatsFragment
          ...SimpleContentFragment
          ...ServicesFragment
          ...backgroundVideoHeaderFragment
        }
      }
      seo {
        fullHead
        schema {
          raw
        }
      }
    }
    # TEMPORARY QUERY
    allWpPost(limit: 3) {
      nodes {
        ...PostDataQuery
      }
    }
    ## TEMPORARY QUERY
    googlePlacesPlace(place_id: { eq: $placeId }) {
      rating
      user_ratings_total
    }
    allGooglePlacesReview(sort: { order: DESC, fields: time }) {
      nodes {
        author_name
        text
        rating
        relative_time_description
      }
    }
  }
`
