import { decodeHtml, getSliderImages } from "./productsHelper"

export const makeSeoProductDescription = shortDescription => {
  if (!shortDescription) {
    return null
  }

  return decodeHtml(shortDescription)
}

export const updateVariableProductSEO = (variant, isGeneric) => {
    if (variant.genericProductData && variant.genericProductData.seo) {
      const availability = getSeoAvailiability(variant.stockStatus, variant.stockQuantity)
      const sliderImages = getSliderImages(variant.genericProductData.featuredImage, variant.genericProductData.galleryImages)
      const seoImages = makeSeoProductImages(sliderImages)
      if(variant.genericProductData.seo.metaDesc && variant.colour && !isGeneric){
        variant.genericProductData.seo.headTags = updateVariableProductSeoHeadTags(variant, availability, sliderImages, seoImages)
      } else {
        variant.genericProductData.seo.headTags = makeProductSeoHeadTags(
            variant.genericProductData.seo.fullHead,
            variant.brand,
            availability.social,
            seoImages.social
        )
      }
    } else {
      console.error('could not find seo for ' + variant.name)
    }
}

const standardStringPattern = "[A-Za-z0-9\\s|,\\/\\-%+£!?><&;#\\.°\\\"’\\(\\)']+"

const updateVariableProductSeoHeadTags = (variant, availability, sliderImages, seoImages) => {

  let seoHeadTags = makeVariableProductSeoHeadTags(
      variant.genericProductData.seo,
      variant.brand,
      availability.social,
      seoImages.social
  )

  const patternSearchOgTitle = '(property=\\"og:title\\" content=\\")(' + standardStringPattern + ')?(' + variant.name + ')(' + standardStringPattern + ')?(\\" \/>)'
  const metaPropertyToReplace = new RegExp(patternSearchOgTitle, 'gi')

  const ogTitleFound = seoHeadTags.search(metaPropertyToReplace) >= 0
  if (ogTitleFound) {
    // console.warn('found in seoHeadTags (og:title) for ' + variant.name + ' ' + variant.colour, seoHeadTags)
    // const escapedProductName = variant.name.replace(/"/g, '&quot;')
    seoHeadTags = seoHeadTags.replace(metaPropertyToReplace, '$1$2$3 ' + variant.colour + "$4$5")
  } else {
    console.warn('Search could not find Head tag (og:title) for ' + variant.name + ' ' + variant.colour + ' pattern ' + patternSearchOgTitle)
  }

  const patternSearchTitle = '(<title>)(.+)?(' + variant.name + ')(.+)?(</title>)'
  const titleRegex = new RegExp(patternSearchTitle, 'gi')
  const titleFound = seoHeadTags.search(titleRegex) >= 0
  if (titleFound) {
    // console.warn('found in seoHeadTags (<title>) for ' + variant.name + ' ' + variant.colour)
    seoHeadTags = seoHeadTags.replace(titleRegex, '$1$2$3 ' + variant.colour + '$4$5')
  } else {
    console.warn(seoHeadTags.search(titleRegex) + 'Search could not find Head tag (<title>) for ' + variant.name + ' ' + variant.colour + ' pattern ' + patternSearchTitle)
  }
  //TODO

  const patternSearchDescription = '(property=\\"og:description\\" content=\\")(' + standardStringPattern + ')?(' + variant.name + ')(' + standardStringPattern + ')?(\\" \/>)'
  const ogDescriptionRegExp = new RegExp(patternSearchDescription, 'gi')

  const ogDescriptionFound = seoHeadTags.search(ogDescriptionRegExp) >= 0
  if (ogDescriptionFound) {
    // console.warn('found in seoHeadTags (og:title) for ' + variant.name + ' ' + variant.colour, seoHeadTags)
    // const escapedProductName = variant.name.replace(/"/g, '&quot;')
    seoHeadTags = seoHeadTags.replace(ogDescriptionRegExp, '$1$2$3 ' + variant.colour + '$4$5')
  } else {
    console.warn('Search could not find Head tag (og:description) for ' + variant.name + ' ' + variant.colour + ' pattern ' + patternSearchDescription)
  }

  const patternSearchMetaDescription = '(<meta name="description" content=")(.+)?(' + variant.name + ')'
  const meteDescriptionRegex = new RegExp(patternSearchMetaDescription, 'gi')
  const metaDescriptionFound = seoHeadTags.search(meteDescriptionRegex) >= 0
  if (metaDescriptionFound) {
    // console.warn('found in seoHeadTags (<title>) for ' + variant.name + ' ' + variant.colour)
    seoHeadTags = seoHeadTags.replace(meteDescriptionRegex, '$1$2$3 ' + variant.colour)
  } else {
    console.warn(seoHeadTags.search(titleRegex) + 'Search could not find Head tag (<meta name="description">) for ' + variant.name + ' ' + variant.colour + ' pattern ' + patternSearchMetaDescription)
  }

  // let description = seoDescription.concat('', attributeColour)
  // seoHeadTags = seoHeadTags.replace(/meta property="og:description"/gi,'meta property="og:description" content="' + description + '"')

  return seoHeadTags
}

export const getSeoAvailiability = (stockStatus, stockQuantity) => {
  if (stockStatus === "IN_STOCK") {
    return { schema: "https://schema.org/InStock", social: "in stock" }
  }
  if (stockQuantity && stockQuantity.length) {
    return { schema: "https://schema.org/InStock", social: "in stock" }
  }

  return { schema: "https://schema.org/OutOfStock", social: "out of stock" }
}

export const makeSeoProductImages = sliderImages => {
  let schemaImages = []
  let socialImages = ""
  sliderImages.forEach(image => {
    if (schemaImages.includes(image.sourceUrl)) {
      return
    }

    schemaImages.push(image.sourceUrl)

    socialImages =
      socialImages +
      `<meta property="og:image" content="${image.sourceUrl}" />
    <meta property="og:image:width" content="${image.localFile.childImageSharp.gatsbyImageData.width}" />
    <meta property="og:image:height" content="${image.localFile.childImageSharp.gatsbyImageData.height}" />`
  })

  return { schema: schemaImages, social: socialImages }
}

export const makeVariableProductSeoHeadTags = (
  seo,
  brand,
  availability,
  images
) => {

  //This function was introduced because Yoast added dashes between words in the fullHead og:title and og:description
  //where title was used making title like: "Moto Guzzi V7 Special 850 E5" to be "Moto Guzzi V7-Special 850-E5"
  //that makes it impossible to replace title and description with a colour later in the code
  if (seo.fullHead) {
    if (seo.title) {
      const patternSearchOgTitle = '(property=\\"og:title\\" content=\\")(' + standardStringPattern + ')(\\" \\/>)'
      const metaPropertyToReplace = new RegExp(patternSearchOgTitle, 'gi')
      const ogTitleFound = seo.fullHead.search(metaPropertyToReplace) >= 0
      if (ogTitleFound) {
        seo.fullHead = seo.fullHead.replace(metaPropertyToReplace, '$1' + seo.title.replace(/"/g, "&quot;") + '$3')
      } else {
        console.warn("didn't find og:title " + seo.title)
      }
    }

    if (seo.metaDesc) {
      const patternSearchOgMetaDesc = '(property=\\"og:description\\" content=\\")(' + standardStringPattern + ')(\\" \\/>)'
      const metaPropertyToReplace = new RegExp(patternSearchOgMetaDesc, 'gi')
      const ogDescriptionFound = seo.fullHead.search(metaPropertyToReplace) >= 0
      if (ogDescriptionFound) {
        seo.fullHead = seo.fullHead.replace(metaPropertyToReplace, '$1' + seo.metaDesc.replace(/"/g, "&quot;") + '$3')
      } else {
        console.warn("didn't find og:description " + seo.metaDesc + " pattern " + patternSearchOgMetaDesc)
        console.log(seo.fullHead)
      }
    }
  } else {
    console.warn("seo.fullHead should not be empty" + seo.title)
  }

  return makeProductSeoHeadTags(
      seo.fullHead,
      brand,
      availability,
      images
  )
}

export const makeProductSeoHeadTags = (
  fullHead,
  brand,
  availability,
  images
) => {
  let headTags = fullHead

  headTags = headTags.replace(/<script.*\/script>\\n/, "")
  headTags =
    headTags +
    `<meta name="twitter:card" content="summary_large_image" />
    <meta property="product:brand" content="${brand}" />
    <meta property="og:availability" content="${availability}" />
    <meta property="product:availability" content="${availability}" />
    <meta property="product:condition" content="new" />\n`

  headTags = headTags + images

  return headTags
}

export const makeProductPageSchema = (
  rawSchema,
  name,
  images,
  seoDescription,
  sku,
  pathname,
  availability,
  brand,
  rawPrice,
  onSale,
  breadcrumbData
) => {

  const productSchema = {
    "@id": `${process.env.SITE_URL}#/schema/Product/${sku}`,
    "@type": "Product",
    name: name,
    image: images,
    description: seoDescription,
    sku: sku,
    // gtin13: "",
    url: `${process.env.SITE_URL}${pathname}`,
    brand: {
      "@type": "Brand",
      name: brand,
    },
    offers: {
      "@type": "Offer",
      priceCurrency: "GBP",
      price: rawPrice,
      url: `${process.env.SITE_URL}${pathname}`,
      itemCondition: "https://schema.org/NewCondition",
      availability: availability,
      seller: {
        "@type": "Organization",
        name: process.env.ORG_TRADING_NAME,
        url: process.env.SITE_URL,
      },
      shippingDetails: {
        "@type": "OfferShippingDetails",
        shippingRate: {
          "@type": "MonetaryAmount",
          value: "0",
          currency: "GBP",
        },
        shippingDestination: [
          {
            "@type": "DefinedRegion",
            addressCountry: "GB",
          },
        ],
      },
      hasMerchantReturnPolicy: {
        "@type": "MerchantReturnPolicy",
        applicableCountry: "GB",
        returnPolicyCategory:
          "https://schema.org/MerchantReturnFiniteReturnWindow",
        merchantReturnDays: process.env.SCHEMA_RETURN_DAYS,
        returnMethod: process.env.SCHEMA_RETURN_METHOD,
        returnShippingFeesAmount: {
          "@type": "MonetaryAmount",
          value: "0",
          currency: "GBP",
          description:
            "Return shipping costs are the responsibility of the customer and will vary based on the shipping method.",
        },
        merchantReturnLink: `${process.env.SITE_URL}${process.env.SCHEMA_RETURN_PATH}`,
      },
    },
  }

  const shippingFee = Number(process.env.SHIPPING_FEE)
  const freeShippingThreshold = Number(process.env.FREE_SHIPPING_MINIMUM)

  if (shippingFee && rawPrice < freeShippingThreshold) {
    productSchema.offers.shippingDetails.shippingRate.value = shippingFee
    productSchema.offers.shippingDetails.shippingRate["description"] = `Free shipping on orders over £${freeShippingThreshold}`
  }

  if (process.env.ORG_TRADING_NAME === "Ridergear") {
    Object.assign(productSchema.offers.shippingDetails, {
      deliveryTime: {
        "@type": "ShippingDeliveryTime",
        handlingTime: {
          "@type": "QuantitativeValue",
          minValue: 0,
          maxValue: 1,
          unitCode: "DAY",
        },
        transitTime: {
          "@type": "QuantitativeValue",
          minValue: 1,
          maxValue: 2,
          unitCode: "DAY",
        },
      },
    })
  }

  if(!onSale) {
    // At the moment there is no CMS data to set the `priceValidUntil` prop for products on sale, so for the moment the prop is only being set for products with a regular price to clear warnings where possible, but avoid GSC penalties. Once the data is available this can be refactored.
    const currentYear = new Date().getFullYear()
    const currentDate = new Date()

    const priceReviewDate1 = new Date(
      `${currentYear}-${process.env.BIANNUAL_PRICE_REVIEW_DATE_1_MMDD}`
    )
    const priceReviewDate2 = new Date(
      `${currentYear}-${process.env.BIANNUAL_PRICE_REVIEW_DATE_2_MMDD}`
    )

    let nextPriceReviewDate = priceReviewDate1.toISOString().split("T")[0]

    if (currentDate - priceReviewDate2 > 0) {
      nextPriceReviewDate = nextPriceReviewDate.replace(
        currentYear,
        currentYear + 1
      )
    } else if (currentDate - priceReviewDate1 > 0) {
      nextPriceReviewDate = priceReviewDate2.toISOString().split("T")[0]
    }

    Object.assign(productSchema.offers, {
      priceValidUntil: nextPriceReviewDate,
    })
  }

  let schema = JSON.parse(rawSchema)

  const schemaTypes = schema["@graph"].reduce((types, graph, index) => {
    const allTypes = types
    allTypes[graph["@type"]] = index

    return allTypes
  }, {})

  if (schemaTypes.WebSite !== undefined) {
    delete schema["@graph"][schemaTypes.WebSite].potentialAction
  }

  if (schemaTypes.BreadcrumbList !== undefined) {
    let breadcrumbItemListUpdate = [
      schema["@graph"][schemaTypes.BreadcrumbList].itemListElement[0]
    ]

    let breadcrumbLastItem =
      schema["@graph"][schemaTypes.BreadcrumbList].itemListElement[
        schema["@graph"][schemaTypes.BreadcrumbList].itemListElement.length - 1
      ]

    breadcrumbData.map((item, i) => {
      return breadcrumbItemListUpdate.push({
        "@type": "ListItem",
        position: i + 2,
        name: item.name,
        item: `${process.env.SITE_URL}${item.path}`,
      })
    })

    breadcrumbLastItem.position = breadcrumbItemListUpdate.length + 1
    breadcrumbItemListUpdate.push(breadcrumbLastItem)
    schema["@graph"][
      schemaTypes.BreadcrumbList
    ].itemListElement = breadcrumbItemListUpdate
  }

  schema["@graph"].push(productSchema)

  schema = JSON.stringify(schema)

  return schema
}

export const makePostSchema = (rawSchema, uri, image) => {
  let schema = JSON.parse(rawSchema.replace(/\/product\//g, "/products/"))

  const schemaTypes = schema["@graph"].reduce((types, graph, index) => {
    const allTypes = types
    allTypes[graph["@type"]] = index

    return allTypes
  }, {})

  if (schemaTypes.WebSite !== undefined) {
    delete schema["@graph"][schemaTypes.WebSite].potentialAction
  }

  if (schemaTypes.Article !== undefined) {
    delete schema["@graph"][schemaTypes.Article].wordCount
    delete schema["@graph"][schemaTypes.Article].commentCount
    delete schema["@graph"][schemaTypes.Article].potentialAction
  }

  if (schemaTypes.Person !== undefined) {
    if (schema["@graph"][schemaTypes.Person].name === process.env.ORG_NAME) {
      schema["@graph"][schemaTypes.Article].author = {
        "@id": `${process.env.SITE_URL}/#organization`
      }

      delete schema["@graph"][schemaTypes.Person]
    } else {
      delete schema["@graph"][schemaTypes.Person].url
    }
  }

  schema = JSON.stringify(schema)

  return schema
}

export const makePageSchema = rawSchema => {
  let schema = JSON.parse(rawSchema.replace(/\/product\//g, "/products/"))

  const schemaTypes = schema["@graph"].reduce((types, graph, index) => {
    const allTypes = types
    allTypes[graph["@type"]] = index

    return allTypes
  }, {})

  if (schemaTypes.WebSite !== undefined) {
    delete schema["@graph"][schemaTypes.WebSite].potentialAction
  }

  schema = JSON.stringify(schema)

  return schema
}
